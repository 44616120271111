/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location }) => {
  if (location.hash === '') {
    document.documentElement.scrollTop = document.body.scrollTop = 0
  }
}
